import(/* webpackMode: "eager" */ "/home/shankesh/webdesign/prateekshawebdesign.com/next.js/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/home/shankesh/webdesign/prateekshawebdesign.com/next.js/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/home/shankesh/webdesign/prateekshawebdesign.com/next.js/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/home/shankesh/webdesign/prateekshawebdesign.com/next.js/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/shankesh/webdesign/prateekshawebdesign.com/next.js/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/home/shankesh/webdesign/prateekshawebdesign.com/next.js/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/home/shankesh/webdesign/prateekshawebdesign.com/next.js/node_modules/next/font/google/target.css?{\"path\":\"src/app/(front)/layout.tsx\",\"import\":\"Raleway\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"100\",\"200\",\"300\",\"400\",\"500\",\"600\",\"700\",\"800\",\"900\"],\"style\":[\"normal\",\"italic\"],\"display\":\"swap\",\"variable\":\"--font-raleway\"}],\"variableName\":\"raleway\"}");
;
import(/* webpackMode: "eager" */ "/home/shankesh/webdesign/prateekshawebdesign.com/next.js/node_modules/next/font/google/target.css?{\"path\":\"src/app/(front)/layout.tsx\",\"import\":\"Lora\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"500\",\"600\",\"700\"],\"style\":[\"normal\",\"italic\"],\"display\":\"swap\",\"variable\":\"--font-lora\"}],\"variableName\":\"lora\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/shankesh/webdesign/prateekshawebdesign.com/next.js/src/components/Footer.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/shankesh/webdesign/prateekshawebdesign.com/next.js/src/components/Header.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/shankesh/webdesign/prateekshawebdesign.com/next.js/src/components/HotjarTracking.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/shankesh/webdesign/prateekshawebdesign.com/next.js/src/components/PopupSeconds.tsx");
;
import(/* webpackMode: "eager" */ "/home/shankesh/webdesign/prateekshawebdesign.com/next.js/src/styles/global.scss");
