"use client";
import React, { useState } from "react";
import axios from "axios";
import Image from "next/image";
import Modal from "react-modal";
import FormContactUs from "./FormContactUs";

const Popup = ({
  isVisible,
  onClose,
  title = "",
  heading,
  imageSrc,
  buttonText = "Submit",
  consentText = "text-gray-700",
}) => {
  return (
    <Modal
      isOpen={isVisible}
      onRequestClose={onClose}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 9998,
        },
        content: {
          top: "55%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          padding: "0px",
          textAlign: "left",
          border: "1px solid #ccc",
          zIndex: 9999,
        },
      }}
    >
      <div className="flex items-center justify-center md:w-full p-0 m-0 overflow-hidden relative">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 mt-4 px-2 md:px-4 py-2 bg-blue-500 text-white rounded-lg z-20"
          style={{ zIndex: 10000 }}
        >
          X
        </button>
        <div className="flex shadow-lg rounded-lg overflow-hidden relative max-w-4xl w-full">
          <div className="relative z-10 flex flex-col md:w-3/4 p-4 md:p-8">
            <h3 className="font-semibold mb-2 text-3xl">{title}</h3>
            <div>{heading}</div>
            <FormContactUs buttonText={buttonText} consentText={consentText} />
          </div>
          <div className="md:w-1/2">
            <Image
              src={imageSrc}
              fill
              className="absolute inset-0 w-full h-full object-cover right-0"
              alt={heading}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Popup;
